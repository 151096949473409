import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Select from "react-select";
import PropTypes from 'prop-types';
import styles from './Events.module.css';
import { API_URL } from '../../constants/apiUrls';
import { showSnackBar } from '../../../redux/snackBarSlice';
import { setShowLoginDialog } from '../../../redux/userSlice';
import UI from '../../constants/ui';

function EventRegistrationForm(props) {
  const dispatch = useDispatch();
  const {
    companyName,
    eventName,
    eventID,
    startDate,
    endDate,
    assessmentIds = [],
    technologies,
    loading,
    interviewSkills,
  } = props;

  const [formData, setFormData] = useState({
    technologies: [],
  });
  const [error, setError] = useState('');

  // Memoize matched technologies
  const matchedTechnologies = useMemo(() =>
    assessmentIds.length ? technologies.filter((option) => assessmentIds.includes(option.value)) : []
    , [assessmentIds, technologies]);


  useEffect(() => {
    const newTechnologies = matchedTechnologies.map((tech) => tech.value);
    // Only update if formData.technologies is empty 
    if (formData.technologies.length === 0 && newTechnologies.length > 0) {
      setFormData((prev) => ({
        ...prev,
        technologies: newTechnologies,
      }));
    }
  }, [matchedTechnologies]); 

  const assessmentSkillIds = formData.technologies.join(',');

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.technologies.length === 0) {
      setError('At least one skill must be selected!');
      return;
    }
    try {
      let url = `${API_URL.EVENT_REGISTRATION}/${eventID}/registration`;

      if (assessmentSkillIds) {
        url += `?assessmentIds=${assessmentSkillIds}`;
      }
  
      const res = await axios.put(url);

      if (res.data.code === -1) {
        dispatch(showSnackBar({ setopen: true, message: 'You have already registered for this event.', severity: 'error' }));
      } else {
        dispatch(showSnackBar({ setopen: true, message: 'Successfully Registered!', severity: 'success' }));
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(setShowLoginDialog(true));
        dispatch(showSnackBar({ setopen: true, message: 'User not logged in!', severity: 'error' }));
      } else {
        dispatch(showSnackBar({ setopen: true, message: 'Registration failed. Please try again.', severity: 'error' }));
      }
    }
  };

  return (
    <div style={{ padding:'0px 20px 20px 20px', maxWidth:'400px' }}>
      {companyName !== 'Global' && <div className={styles['form-content']}><strong>{UI.COMPANY}:</strong> {companyName}</div>}
      <div className={styles['form-content']}><strong>{UI.EVENT_NAME}:</strong> {eventName}</div>
      <div className={styles['form-content']}><strong>{UI.START_DATE}:</strong> {startDate}</div>
      <div className={styles['form-content']}><strong>{UI.END_DATE}:</strong> {endDate}</div>

      <form onSubmit={onSubmit} className={styles.form}>
        <div className={styles['form-group']}>
          <label htmlFor="assessment-select" className={styles['form-content']}>
            {UI.ASSESSMENTS}
            {assessmentIds?.length === 0 && <span style={{ color: 'red' }}>*</span>}
          </label>
          {assessmentIds.length > 0 && matchedTechnologies.length > 0 ? (
            <div>{matchedTechnologies.map((tech) => (
              <span key={tech.value} className={styles['tech-name']}>{`#${tech.label}`}</span>
            ))}</div>
          ) : (
            <>
              <Select
                id="assessment-select"
                isMulti
                options={technologies}
                isLoading={loading}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions ? selectedOptions.map((opt) => opt.value) : [];
                  // Update formData only if there is a change
                  if (JSON.stringify(selectedValues) !== JSON.stringify(formData.technologies)) {
                    setFormData((prev) => ({ ...prev, technologies: selectedValues }));
                  }
                }}
                classNamePrefix="react-select"
                placeholder="Search and select assessments."
              />
              {error && <p style={{ color: 'red', fontSize:'13px', paddingTop: '10px', paddingLeft: '10px' }}>{error}</p>}
              {!error && <p style={{ color: '#0E70B3', fontSize:'13px',  paddingTop: '10px', paddingLeft: '10px' }}>{UI.SELECT_AT_LEAST_3_EVENTS}</p>}
            </>
          )}
          {interviewSkills?.length > 0 && (
            <>
              <span className={styles['form-content']}>{UI.INTERVIEWS}</span>
              <div>{interviewSkills.map((interview) => (
                <span key={interview} className={styles['tech-name']}>{`#${interview}`}</span>
              ))}</div>
            </>
          )}
        </div>
        <button type="submit" className={styles['submit-button']}>{UI.REGISTER_}</button>
      </form>
    </div>
  );
}

export default EventRegistrationForm;

EventRegistrationForm.propTypes = {
  companyName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  eventID: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  assessmentIds: PropTypes.arrayOf(PropTypes.string),
  technologies: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  interviewSkills: PropTypes.arrayOf(PropTypes.string),
};

EventRegistrationForm.defaultProps = {
  assessmentIds: [],
  interviewSkills: [],
};
