import React, { Suspense, lazy, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import {
  setLoggedInUserRole,
  setRelevantToCareerPath,
  setRequestsComplete,
  setRequestsInComplete,
  setRerenderpermissions,
} from '../../../redux/userSlice';
import {
  getAvailablePointsUser,
  getNotificationCountUser,
} from '../../../redux/apiCalls';
import NavbarComponent from '../navbarComponent';
import Banners from '../../components/banner/Banners';
import LOGIN_TYPE from '../../constants/loginType';
import lazyLoadingFallback from '../../helpers/lazyLoadingFallback';
// import UnsubscribeNotification from '../notifications/UnsubscribeNotification';
// import Footer from '../footer';
import Loader from '../../components/loader';
import PrivateRoute from '../../components/privateRoute';
import ProtectedRoute from '../../components/protectedRoute';
import PublicRoute from '../../components/publicRoute';
import { EMPTY_ARRAY } from '../../constants';
import { API_URL } from '../../constants/apiUrls';
import STORAGE_KEY from '../../constants/storageKey';
import UI from '../../constants/ui';
import URL, { ROLES_TYPES } from '../../constants/urls';
import { getDefaultHomeUrl } from '../../helpers/getRedirectUrl';
import {
  getLoginDetailFromSession,
  setCareerPath,
  setLoginDetailInSession,
} from '../../helpers/sessionDetails';
import { getRequest } from '../../services';
import Error from '../error/Error';
import ErrorFallback from '../error/ErrorFallback';
import Login from '../loginRegisterFlow/login';
import Register from '../loginRegisterFlow/register';
import ResendEmail from '../loginRegisterFlow/resendemail';
import VerifyEmail from '../loginRegisterFlow/verifyEmail';
import Payment from '../payment';
import { EMPLOYER_LOGIN_ESTATE } from '../../constants/loginStates.constants';
import hasPermission from '../../hooks/hasPermission';
import { PERMISSIONS_ACTIONS } from '../../formatter/permissions.bootstrap';
import Footer from '../../../amchome/components/Footer';
import Home from '../newHomeScreen/Home';
import Events from '../Events/Events';
import FAQs from '../FAQS/FAQs';
import OauthLogin from '../loginRegisterFlow/oauthLogin';

const CareerPath = lazy(() => import('../CareerPath/CareerPath'));
const CareerPathAchieved = lazy(() =>
  import('../CareerPath/CareerPathAchieved')
);
const BlogPage = lazy(() => import('../Blog/BlogPage'));
const BlogDetails = lazy(() => import('../Blog/BlogDetails'));
const MockAssessment = lazy(() => import('../MockAssessment'));
const AssessmentReport = lazy(() => import('../AssessmentReport'));
const UnsubscribeEmail = lazy(() =>
  import('../notifications/UnsubscribeEmail')
);

const AiAssessmentDetails = lazy(() =>
  import('../createAssessment/AIAssessmentDetails')
);
const PricingDashboard = lazy(() => import('../Pricing/PricingDashboard'));
const AssessmentPage = lazy(() => import('../assessment/AssessmentPage'));
const AdminCandidatesDetails = lazy(() =>
  import('../AdminPanel/AdminCandidatesDetails')
);
const AdminAllJobsPosted = lazy(() =>
  import('../AdminPanel/AdminAllJobsPosted')
);
const AdminAnalytics = lazy(() => import('../AdminPanel/AdminAnalytics'));
const AssessedCandidates = lazy(() =>
  import('../AssessmentCandidate/AssessedCandidates')
);
const AssessmentDetails = lazy(() =>
  import('../createAssessment/AssessmentDetails')
);
const CustomAssessment = lazy(() => import('../assessment/CustomAssessment'));
const ForJobSeekers = lazy(() =>
  import('../../../amchome/screens/job-seeker/ForJobSeekers')
);
const ForRecruiters = lazy(() =>
  import('../../../amchome/screens/recruiters/ForRecruiters')
);
const FindCandidates = lazy(() => import('../findCandidates'));
const FindJobs = lazy(() => import('../findJobs'));
const FindJobsSearches = lazy(() => import('../findJobsSearches'));
const FindCandidateSearches = lazy(() => import('../findCandidateSearches'));
const PostJob = lazy(() => import('../postJob'));
const Guidelines = lazy(() => import('../assessment/guidelines'));
const EmployerProfile = lazy(() => import('../employerProfile'));
const JobStatus = lazy(() => import('../jobStatus'));
const CandidateProfileForm = lazy(() => import('../candidateProfileForm'));
const PostedJobs = lazy(() => import('../postedJobs'));
const MyCandidates = lazy(() => import('../myCandidates'));
const MyJobs = lazy(() => import('../myJobs'));
const MyProfileActivity = lazy(() => import('../myProfileActivity'));
const Assessment = lazy(() => import('../assessment'));
const StartAssessment = lazy(() => import('../assessment/StartAssessment'));
const Notifications = lazy(() => import('../notifications/Notifications'));
const ForgotPassword = lazy(() =>
  import('../loginRegisterFlow/forgotPassword')
);
const UpdatePassword = lazy(() =>
  import('../loginRegisterFlow/updatepassword')
);
const ConfirmEmail = lazy(() => import('../loginRegisterFlow/confirmEmail'));
const ChangePassword = lazy(() =>
  import('../loginRegisterFlow/changepassword')
);
const Confirm = lazy(() => import('../loginRegisterFlow/confirm'));
const Employer = lazy(() => import('../jobs/employer'));
const Setting = lazy(() => import('../settings/Setting'));
const Offers = lazy(() => import('../offers/Offers'));
const IFrameComp = lazy(() => import('../../components/iframecomp/iframecomp'));
const UnivDashboard = lazy(() => import('../university/UnivDashboard'));
const JobPage = lazy(() => import('../jobs/JobPage'));
const CandidatePage = lazy(() => import('../candidates/CandidatePage'));
const FindJobsLoggedIn = lazy(() => import('../findJobs/FindJobsLoggedIn'));
const FindCandidatesLoggedIn = lazy(() =>
  import('../findCandidates/FindCandidatesLoggedIn')
);
const AdminAction = lazy(() => import('../AdminPanel/AdminAction'));
const ProjectDetails = lazy(() => import('../projectsPages/ProjectDetails'));
const ProjectsScreen = lazy(() => import('../projectsPages/ProjectsScreen'));
const CompanyEmployeeProfile = lazy(() =>
  import('../companyEmployeeProfile/CompanyEmployeeProfile')
);

function RoutesComponent() {
  const loggedInUserRole = useSelector((state) => state.user.loggedInUserRole);
  const [isLoading, setIsLoading] = useState(true);
  const [showNavBar, setShowNavBar] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const dispatch = useDispatch();
  const reRenderPermissionsCode = useSelector(
    (state) => state.user.reRenderPermissionsCode
  );

  const userRole = getLoginDetailFromSession();
  const project = useMemo(
    () => getLoginDetailFromSession()?.project,
    [reRenderPermissionsCode]
  );
  const isApprovalPending = useMemo(
    () =>
      getLoginDetailFromSession()?.estate ===
      EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING,
    [reRenderPermissionsCode]
  );
  const hasUpdateJobPermission = useMemo(
    () => hasPermission(PERMISSIONS_ACTIONS.UPDATE_JOB),
    [reRenderPermissionsCode]
  );
  useEffect(() => {
    window.dispatchEvent(new Event('storage'));
  }, [loggedInUserRole]);

  const handleSetUserLoggedIn = () => {
    dispatch(setLoggedInUserRole(getLoginDetailFromSession()?.role));
  };

  const handleLogOut = () => {
    dispatch(setLoggedInUserRole());
    dispatch(setRelevantToCareerPath(false));
    sessionStorage.removeItem(STORAGE_KEY.IS_SPOT_ON_MOCK_INTERVIEWS);
    sessionStorage.removeItem(STORAGE_KEY.REDIRECTION_URL);
    sessionStorage.removeItem(STORAGE_KEY.CAREER_PATH);
  };

  useEffect(() => {
    if (window.location.pathname === URL.MOCK_INTERVIEW) {
      sessionStorage.setItem(STORAGE_KEY.IS_SPOT_ON_MOCK_INTERVIEWS, true);
    }
    if (
      window.location.pathname === URL.LOGIN ||
      window.location.pathname === URL.HOME
    ) {
      sessionStorage.removeItem(STORAGE_KEY.REDIRECTION_URL);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    // Added this listener because if we have multiple tabs in browser it should reflect in every tabs.
    window.addEventListener('storage', (event) => {
      if (event.key === STORAGE_KEY.SESSION_DETAILS) {
        const userDetail = getLoginDetailFromSession();
        // check if event was triggered in another tab
        if (
          event.newValue !== null &&
          userDetail !== event.newValue &&
          (userDetail?.role === LOGIN_TYPE.EMPLOYER ||
            userDetail?.role === LOGIN_TYPE.CANDIDATE)
        ) {
          dispatch(setRequestsInComplete());
          // setTimeout can be removed here
          setTimeout(() => {
            getNotificationCountUser(dispatch);
            getAvailablePointsUser(dispatch);
            dispatch(setRequestsComplete());
            dispatch(setRerenderpermissions());
          }, 2000);
        }
      }
      handleSetUserLoggedIn();
    });
  }, EMPTY_ARRAY);
  useEffect(() => {
    const userDetail = getLoginDetailFromSession();
    dispatch(setRequestsInComplete());
    if (userDetail) {
      setIsLoading(true);
      getRequest(API_URL.CREDENTIAL)
        .then((userCredential) => {
          if (userCredential?.careerpath) {
            setCareerPath(userCredential?.careerpath);
            dispatch(setRelevantToCareerPath(true));
          }
          if (userCredential.role === LOGIN_TYPE.CANDIDATE) {
            getNotificationCountUser(dispatch);
            getAvailablePointsUser(dispatch);
            dispatch(setRequestsComplete());
            dispatch(setRerenderpermissions());
          } else if (
            userCredential.role === LOGIN_TYPE.EMPLOYER &&
            userDetail?.project
          ) {
            getNotificationCountUser(dispatch);
            getAvailablePointsUser(dispatch);
            dispatch(setRequestsComplete());
            dispatch(setRerenderpermissions());
          }
          dispatch(setLoggedInUserRole(userCredential.role));
          setLoginDetailInSession(userCredential);
        })
        .catch(() => {
          localStorage.removeItem(STORAGE_KEY.CANDIDATE_DETAILS);
          localStorage.removeItem(STORAGE_KEY.EMPLOYER_DETAILS);
          localStorage.removeItem(STORAGE_KEY.SESSION_DETAILS);
          localStorage.removeItem(STORAGE_KEY.PRICE_PLAN_DETAILS);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, EMPTY_ARRAY);
  if (isLoading) {
    return <Loader />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter className="mt-3">
        {showNavBar && (
          <NavbarComponent
            setLoggedInUserRole={handleLogOut}
            loggedInUserRole={loggedInUserRole}
            setShowFooter={setShowFooter}
          />
        )}
        {loggedInUserRole &&
          (loggedInUserRole === LOGIN_TYPE.CANDIDATE ||
            loggedInUserRole === LOGIN_TYPE.EMPLOYER) &&
          showNavBar && (
            <div className="p-2">
              <Banners />
            </div>
          )}
        <Routes>
          {/* Employer URLs */}
          <Route
            path={URL.EMPLOYER_PROFILE}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_EMPLOYER_PROFILE}
                description={UI.DESC_EMPLOYER_PROFILE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <EmployerProfile setLoggedInUserRole={handleLogOut} />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.COMPANY_EMPLOYEE_PROFILE}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_COMPANY_EMPLOYEE_PROFILE}
                description={UI.DESC_COMPANY_EMPLOYEE_PROFILE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CompanyEmployeeProfile />
                </Suspense>
              </PrivateRoute>
            }
          />
          {!isApprovalPending && (
            <>
              {hasUpdateJobPermission && (
                <Route
                  path={URL.POST_JOB}
                  element={
                    <PrivateRoute
                      role={loggedInUserRole}
                      roleTypes={ROLES_TYPES.EMPLOYER}
                      title={UI.TITLE_POST_JOB}
                      description={UI.DESC_POST_JOB}
                    >
                      <Suspense fallback={lazyLoadingFallback()}>
                        <PostJob />
                      </Suspense>
                    </PrivateRoute>
                  }
                />
              )}
              <Route
                path={URL.POSTED_JOBS}
                element={
                  <PrivateRoute
                    role={loggedInUserRole}
                    roleTypes={ROLES_TYPES.EMPLOYER}
                    title={UI.TITLE_POSTED_JOB}
                    description={UI.DESC_POSTED_JOB}
                  >
                    <Suspense fallback={lazyLoadingFallback()}>
                      <PostedJobs />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path={URL.MY_CANDIDATES}
                element={
                  <PrivateRoute
                    role={loggedInUserRole}
                    roleTypes={ROLES_TYPES.EMPLOYER}
                    title={UI.TITLE_MY_CANDIDATES}
                    description={UI.DESC_MY_CANDIDATES}
                  >
                    <Suspense fallback={lazyLoadingFallback()}>
                      <MyCandidates />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path={URL.PROJECT_DETAILS}
                element={
                  <PrivateRoute
                    role={loggedInUserRole}
                    roleTypes={ROLES_TYPES.EMPLOYER}
                    title={UI.TITLE_PROJECT_DETAILS}
                    description={UI.DESC_PROJECT_DETAILS}
                  >
                    <Suspense fallback={lazyLoadingFallback()}>
                      <ProjectDetails setLoggedInUserRole={handleLogOut} />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            </>
          )}
          <Route
            path={URL.JOB_STATUS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_JOB_STATUS}
                description={UI.DESC_JOB_STATUS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <JobStatus />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.MY_PROJECTS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_MY_PROJECTS}
                description={UI.DESC_MY_PROJECTS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ProjectsScreen isMyProjects />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.COMPANY_PROJECTS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_COMPANY_PROJECTS}
                description={UI.DESC_COMPANY_PROJECTS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ProjectsScreen />
                </Suspense>
              </PrivateRoute>
            }
          />
          {/* Candiate URLs */}
          <Route
            path={URL.EMPLOYER_DETAILS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_EMPLOYER_DETAILS}
                description={UI.DESC_EMPLOYER_DETAILS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Employer setShowNavBar={setShowNavBar} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.JOB_DETAILS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_JOB_DETAILS}
                description={UI.DESC_JOB_DETAILS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <JobPage role={loggedInUserRole} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.CANDIDATE_DETAILS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_CANDIDATE_DETAILS}
                description={UI.DESC_CANDIDATE_DETAILS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CandidatePage role={loggedInUserRole} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.CANDIDATE_PROFILE}
            element={
              <PrivateRoute
                role={loggedInUserRole || userRole?.role}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_CANDIDATE_PROFILE}
                description={UI.DESC_CANDIDATE_PROFILE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CandidateProfileForm />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path={URL.MY_JOBS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_MY_JOBS}
                description={UI.DESC_MY_JOBS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <MyJobs />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.My_Profile_Activity}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_MY_PROFILE_ACTIVITY}
                description={UI.DESC_MY_PROFILE_ACTIVITY}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <MyProfileActivity />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.ASSESSMENT}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_ASSESSMENT}
                description={UI.DESC_ASSESSMENT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Assessment
                    role={loggedInUserRole}
                    setShowNavBar={setShowNavBar}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.SETTING}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_SETTING}
                description={UI.DESC_SETTING}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Setting
                    role={loggedInUserRole}
                    setLoggedInUserRole={handleLogOut}
                  />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.OFFERS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_OFFERS}
                description={UI.DESC_OFFERS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Offers setShowNavBar={setShowNavBar} />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.NOTIFICATIONS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_NOTIFICATIONS}
                description={UI.DESC_NOTIFICATIONS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Notifications />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.ALL_OFFERS}
            element={
              <PublicRoute
                title={UI.TITLE_ALL_OFFERS}
                description={UI.DESC_ALL_OFFERS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Offers isByFooter setShowNavBar={setShowNavBar} />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.GUIDELINES}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_GUIDELINES}
                description={UI.DESC_GUIDELINES}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Guidelines
                    setShowNavBar={setShowNavBar}
                    setShowFooter={setShowFooter}
                  />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.START_ASSESSMENT}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_START_ASSESSMENT}
                description={UI.DESC_START_ASSESSMENT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <StartAssessment />
                </Suspense>
              </PrivateRoute>
            }
          />
          {/* University URLs */}
          <Route
            path={URL.UNIV_STUDENTS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.UNIVERSITY}
                title={UI.TITLE_UNIV_STUDENTS}
                description={UI.DESC_UNIV_STUDENTS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <UnivDashboard />
                </Suspense>
              </PrivateRoute>
            }
          />
          {/* Common URLs */}
          <Route
            path={URL.UPDATE_PASSWORD}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_UPDATE_PASSWORD}
                description={UI.TITLE_UPDATE_PASSWORD}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <UpdatePassword />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.PAYMENT}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_PAYMENT}
                description={UI.DESC_PAYMENT}
              >
                <Payment />
              </PrivateRoute>
            }
          />
          {/* Public URLs */}
          <Route
            path={URL.HOME}
            element={
              !loggedInUserRole ? (
                <PublicRoute title={UI.TITLE_HOME} description={UI.TITLE_DESC}>
                  <Home setShowNavBar={setShowNavBar} />
                </PublicRoute>
              ) : (
                <Navigate replace to={getDefaultHomeUrl(loggedInUserRole)} />
              )
            }
          />
          {!loggedInUserRole ? (
            <Route
              path={URL.FIND_JOBS}
              element={
                <PublicRoute
                  role={loggedInUserRole}
                  title={UI.TITLE_FINDJOBS}
                  description={UI.DESC_FINDJOBS}
                >
                  <Suspense fallback={lazyLoadingFallback()}>
                    <FindJobs setShowNavBar={setShowNavBar} />
                  </Suspense>
                </PublicRoute>
              }
            />
          ) : (
            <Route
              path={URL.FIND_JOBS}
              element={
                <PrivateRoute
                  role={loggedInUserRole}
                  roleTypes={ROLES_TYPES.CANDIDATE}
                  title={UI.TITLE_FINDJOBS}
                  description={UI.DESC_FINDJOBS}
                >
                  <Suspense fallback={lazyLoadingFallback()}>
                    <FindJobsLoggedIn />
                  </Suspense>
                </PrivateRoute>
              }
            />
          )}
          <Route
            path={URL.JOB_SEEKER}
            element={
              <PublicRoute
                title={UI.TITLE_JOB_SEEKER}
                description={UI.DESC_JOB_SEEKER}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ForJobSeekers setShowNavBar={setShowNavBar} />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.RECRUITERS}
            element={
              <PublicRoute
                title={UI.TITLE_JOB_RECRUITERS}
                description={UI.DESC_JOB_RECRUTIES}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ForRecruiters setShowNavBar={setShowNavBar} />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.FOR_RECRUITERS}
            element={
              <PublicRoute
                title={UI.TITLE_JOB_RECRUITERS}
                description={UI.DESC_JOB_RECRUTIES}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ForRecruiters setShowNavBar={setShowNavBar} />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.SEO_FIND_JOBS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_SEO_FIND_JOBS}
                description={UI.DESC_SEO_FIND_JOBS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <FindJobsSearches
                    setLoggedInUserRole={handleSetUserLoggedIn}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.FIND_JOBS_SEARCHES}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_FIND_JOBS_SEARCHES}
                description={UI.DESC_FIND_JOBS_SEARCHES}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <FindJobsSearches
                    setLoggedInUserRole={handleSetUserLoggedIn}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          {!loggedInUserRole || !project ? (
            <Route
              path={URL.FIND_CANDIDATES}
              element={
                <PublicRoute
                  title={UI.TITLE_FINDCANDIDATES}
                  description={UI.DESC_FINDCANDIDATES}
                >
                  <Suspense fallback={lazyLoadingFallback()}>
                    <FindCandidates setShowNavBar={setShowNavBar} />
                  </Suspense>
                </PublicRoute>
              }
            />
          ) : (
            <Route
              path={URL.FIND_CANDIDATES}
              element={
                <PrivateRoute
                  role={loggedInUserRole}
                  roleTypes={ROLES_TYPES.EMPLOYER}
                  title={UI.TITLE_FINDCANDIDATES}
                  description={UI.DESC_FINDCANDIDATES}
                >
                  <Suspense fallback={lazyLoadingFallback()}>
                    <FindCandidatesLoggedIn />
                  </Suspense>
                </PrivateRoute>
              }
            />
          )}
          <Route
            path={URL.SEO_FIND_CANDIDATES}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_SEO_FIND_CANDIDATES}
                description={UI.DESC_SEO_FIND_CANDIDATES}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <FindCandidateSearches
                    setLoggedInUserRole={handleSetUserLoggedIn}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.FIND_CANDIDATES_SEARCHES}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_FIND_CANDIDATES_SEARCHES}
                description={UI.DESC_FIND_CANDIDATES_SEARCHES}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <FindCandidateSearches
                    setLoggedInUserRole={handleSetUserLoggedIn}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.UNSUBSCRIBE}
            element={
              <PublicRoute
                title={UI.UNSUBSCRIBE_MAIL}
                description={UI.UNSUBSCRIBE_MAIL}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <UnsubscribeEmail
                    setShowNavBar={setShowNavBar}
                    setShowFooter={setShowFooter}
                  />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.LOGIN}
            element={
              <PublicRoute title={UI.TITLE_LOGIN} description={UI.DESC_LOGIN}>
                <Login
                  setShowNavBar={setShowNavBar}
                  setLoggedInUserRole={handleSetUserLoggedIn}
                  setUserRoleOnLogout={handleLogOut}
                />
              </PublicRoute>
            }
          />
          <Route
            path={URL.OAUTH_LOGIN}
            element={
              <PublicRoute title={UI.TITLE_LOGIN} description={UI.DESC_LOGIN}>
                <OauthLogin
                  setShowNavBar={setShowNavBar}
                  setLoggedInUserRole={handleSetUserLoggedIn}
                />
              </PublicRoute>
            }
          />
          <Route
            path={URL.REGISTER}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_REGISTER}
                description={UI.DESC_REGISTER}
              >
                <Register setShowNavBar={setShowNavBar} />
              </PublicRoute>
            }
          />
          <Route
            path={URL.VERIFY_EMAIL}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_VERIFY_EMAIL}
                description={UI.DESC_VERIFY_EMAIL}
              >
                <VerifyEmail />
              </PublicRoute>
            }
          />
          <Route
            path={URL.CONFIRM_EMAIL}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_CONFIRM_EMAIL}
                description={UI.DESC_CONFIRM_EMAIL}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ConfirmEmail />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.FORGOT_PASSWORD}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_FORGOT_PASSWORD}
                description={UI.DESC_FORGOT_PASSWORD}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ForgotPassword />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.RESEND_EMAIL}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_RESEND_EMAIL}
                description={UI.DESC_RESEND_EMAIL}
              >
                <ResendEmail />
              </PublicRoute>
            }
          />
          <Route
            path={URL.CONFIRM}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_CONFIRM}
                description={UI.DESC_CONFIRM}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Confirm />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.CHANGE_PASSWORD}
            element={
              <PublicRoute
                role={loggedInUserRole}
                title={UI.TITLE_CHANGE_PASSWORD}
                description={UI.DESC_CHANGE_PASSWORD}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <ChangePassword />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={`${URL.INFORMATION}/:id`}
            element={
              <Suspense fallback={lazyLoadingFallback()}>
                <IFrameComp
                  setShowNavBar={setShowNavBar}
                  setShowFooter={setShowFooter}
                />
              </Suspense>
            }
          />
          <Route
            path={URL.ADMIN_ACTION}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.ADMIN}
                title={UI.TITLE_ADMIN_ACTION}
                description={UI.DESC_ADMIN_ACTION}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AdminAction setShowFooter={setShowFooter} />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.ADMIN_ANALYTICS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.ADMIN}
                title={UI.TITLE_ADMIN_ANALYTICS}
                description={UI.DESC_ADMIN_ANALYTICS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AdminAnalytics />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.ADMIN_ALL_JOBS_POSTED}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.ADMIN}
                title={UI.TITLE_ADMIN_ALL_JOBS_POSTED}
                description={UI.DESC_ADMIN_ALL_JOBS_POSTED}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AdminAllJobsPosted />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.ADMIN_CANDIDATES_DETAILS}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.ADMIN}
                title={UI.TITLE_ADMIN_CANDIDATES_DETAILS}
                description={UI.DESC_ADMIN_CANDIDATES_DETAILS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AdminCandidatesDetails />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.ASSESSED_CANDIDATE}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_ASSESSED_CANDIDATE}
                description={UI.DESC_ASSESSED_CANDIDATE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AssessedCandidates />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.AI_ASSESSED_CANDIDATE}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_ASSESSED_CANDIDATE}
                description={UI.DESC_ASSESSED_CANDIDATE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AssessedCandidates />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.EMPLOYER_ASSESSMENTS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_EMPLOYER_ASSESSMENT}
                description={UI.DESC_ASSESSMENT_EMPLOYER}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CustomAssessment
                    role={loggedInUserRole}
                    setShowNavBar={setShowNavBar}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.AI_INTERVIEW}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_EMPLOYER_ASSESSMENT}
                description={UI.DESC_ASSESSMENT_EMPLOYER}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CustomAssessment
                    role={loggedInUserRole}
                    setShowNavBar={setShowNavBar}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path={URL.ASSESSMENT_DETAILS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_ASSESSMENT_DETAILS}
                description={UI.DESC_ASSESSMENT_DETAILS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AssessmentPage />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path={URL.EMPLOYER_CREATE_ASSESSMENT}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_CREATE_EDIT_ASSESSMENT}
                description={UI.DESC_CREATE_EDIT_ASSESSMENT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AssessmentDetails isCreate />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.EMPLOYER_EDIT_ASSESSMENT}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_CREATE_EDIT_ASSESSMENT}
                description={UI.DESC_CREATE_EDIT_ASSESSMENT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AssessmentDetails />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path={URL.AI_EMPLOYER_CREATE_ASSESSMENT}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_CREATE_EDIT_ASSESSMENT}
                description={UI.DESC_CREATE_EDIT_ASSESSMENT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AiAssessmentDetails isCreate />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={URL.AI_EMPLOYER_EDIT_ASSESSMENT}
            element={
              <PrivateRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_CREATE_EDIT_ASSESSMENT}
                description={UI.DESC_CREATE_EDIT_ASSESSMENT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AiAssessmentDetails />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path={URL.PRICING_DASHBOARD}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.EMPLOYER}
                title={UI.TITLE_PRICING_DASH_BOARD}
                description={UI.DESC_PRICING_DASH_BOARD}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <PricingDashboard setShowNavBar={setShowNavBar} />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path={URL.EVENTS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_EVENTS}
                description={UI.DESC_EVENTS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <Events
                    setShowNavBar={setShowNavBar}
                    role={loggedInUserRole}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path={URL.FAQS}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.COMMON}
                title={UI.TITLE_FAQS}
                description={UI.DESC_FAQS}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <FAQs setShowNavBar={setShowNavBar} />
                </Suspense>
              </ProtectedRoute>
            }
          />

          <Route
            path={URL.MOCK_INTERVIEW}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_MOCK_INTERVIEW}
                description={UI.DESC_MOCK_INTERVIEW}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <MockAssessment setShowNavBar={setShowNavBar} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.CAREER_PATH}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_CAREER_PATH}
                description={UI.DESC_CAREER_PATH}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CareerPath setShowNavBar={setShowNavBar} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.CAREER_PATH_ACHEIEVED}
            element={
              <ProtectedRoute
                role={loggedInUserRole}
                roleTypes={ROLES_TYPES.CANDIDATE}
                title={UI.TITLE_CAREER_PATH}
                description={UI.DESC_CAREER_PATH}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <CareerPathAchieved setShowNavBar={setShowNavBar} />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path={URL.BLOG_PAGE}
            element={
              <PublicRoute
                title={UI.TITLE_BLOG_PAGE}
                description={UI.DESC_BLOG_PAGE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <BlogPage setShowNavBar={setShowNavBar} />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.BLOG_DETAILS}
            element={
              <PublicRoute
                title={UI.TITLE_BLOG_DETAILS_PAGE}
                description={UI.DESC_BLOG_DETAILS_PAGE}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <BlogDetails setShowNavBar={setShowNavBar} />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route
            path={URL.ASSESSMENT_REPORT}
            element={
              <PublicRoute
                title={UI.ASSESSEMENT_REPORT}
                description={UI.ASSESSEMENT_REPORT}
              >
                <Suspense fallback={lazyLoadingFallback()}>
                  <AssessmentReport
                    setShowNavBar={setShowNavBar}
                    setShowFooter={setShowFooter}
                  />
                </Suspense>
              </PublicRoute>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
        {showFooter && <Footer />}
      </BrowserRouter>
    </ErrorBoundary>
  );
}
export default RoutesComponent;
