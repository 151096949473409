import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import UI from '../../constants/ui';

function BasicAccordion(props) {
  const { category, showAllFaqs, searchFaqs, faqs } = props;

  function renderFaqs(value) {
    return (
      <Accordion sx={{ marginBottom: 1.7 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: 'rgb(241,241,241)' }}
        >
          <h1
            style={{
              fontWeight: 'bold',
              fontSize: '1rem',
              marginBottom: '0.1rem',
            }}
          >
            {value.ques}
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          {Object.hasOwn(value, 'ans') ? (
            <h2
              style={{
                paddingBlock: 22,
                color: 'rgb(52, 48, 48)',
                fontWeight: 450,
                fontSize: '1rem',
                lineHeight: '1.5',
                letterSpacing: '0.00938em',
              }}
            >
              {value.ans.length <= 1 ? (
                value.ans[0]
              ) : (
                <ol type="a">
                  {value.ans.map((answer) => (
                    <li>{answer}</li>
                  ))}
                </ol>
              )}
            </h2>
          ) : (
            <h2
              style={{
                paddingBlock: 22,
                color: 'rgb(52, 48, 48)',
                fontWeight: 450,
                fontSize: '1rem',
                lineHeight: '1.5',
                letterSpacing: '0.00938em',
              }}
            >
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: value.anshtml }} />
            </h2>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
  const searchedFaqs = () =>
    faqs?.map((value) => {
      if (!showAllFaqs && value.category?.includes(category)) {
        if (
          value.ques?.toLowerCase().includes(searchFaqs.toLowerCase()) ||
          value.anshtml?.toLowerCase().includes(searchFaqs.toLowerCase()) ||
          value.ans?.toString().toLowerCase().includes(searchFaqs.toLowerCase())
        ) {
          return renderFaqs(value);
        }
      } else if (showAllFaqs) {
        if (
          value.ques?.toLowerCase().includes(searchFaqs.toLowerCase()) ||
          value.anshtml?.toLowerCase().includes(searchFaqs.toLowerCase()) ||
          value.ans?.toString().toLowerCase().includes(searchFaqs.toLowerCase())
        ) {
          return renderFaqs(value);
        }
      }
      return null;
    });
  return (
    <div>
      {searchFaqs !== '' ? (
        searchedFaqs().every((s) => s === null) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              color: 'rgb(102,102,102)',
            }}
          >
            <div>{UI.NO_RESULT_FOUND}</div>
          </div>
        ) : (
          searchedFaqs()
        )
      ) : showAllFaqs ? (
        faqs?.map((value) => renderFaqs(value))
      ) : (
        faqs?.map((value) => {
          if (value.category?.length > 1) {
            return value.category?.map((c) => {
              if (c === category) {
                return renderFaqs(value);
              }
              return null;
            });
          }
          if (value.category[0] === category) {
            return renderFaqs(value);
          }
          return null;
        })
      )}
    </div>
  );
}

BasicAccordion.propTypes = {
  category: PropTypes.number,
  showAllFaqs: PropTypes.bool,
  searchFaqs: PropTypes.string,
  faqs: PropTypes.array,
};

BasicAccordion.defaultProps = {
  category: 1,
  showAllFaqs: false,
  searchFaqs: '',
  faqs: [],
};

export default BasicAccordion;
