import STORAGE_KEY from '../constants/storageKey';

export const getLoginDetailFromSession = () =>
  JSON.parse(localStorage.getItem(STORAGE_KEY.SESSION_DETAILS));

export const setLoginDetailInSession = (sessionDetails) => {
  localStorage.setItem(
    STORAGE_KEY.SESSION_DETAILS,
    JSON.stringify(sessionDetails)
  );
};

export const setPlanDetails = (details) => {
  localStorage.setItem(STORAGE_KEY.PRICE_PLAN_DETAILS, JSON.stringify(details));
};

export const getPlanDetails = () => {
  const UserPricePlanCache = JSON.parse(
    localStorage.getItem(STORAGE_KEY.PRICE_PLAN_DETAILS)
  );
  return UserPricePlanCache;
};

export const setCareerPath = (careerpath) => {
  sessionStorage.setItem(STORAGE_KEY.CAREER_PATH, JSON.stringify(careerpath));
};

export const getCareerPath = () => {
  const careerpath = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY.CAREER_PATH)
  );
  return careerpath;
};
