import PropTypes from 'prop-types';
import React from 'react';
import styles from './NewTag.module.css';

function NewTag({ paddingValue }) {
  return (
    <span className={styles.newTag} style={{ padding: paddingValue }}>
      New
    </span>
  );
}

NewTag.propTypes = {
  paddingValue: PropTypes.number,
};

NewTag.defaultProps = {
  paddingValue: 4,
};

export default NewTag;
