import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { noop } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import axios from 'axios';
import TermAndConditionDialog from '../assessment/TermAndConditionDialog';
import UI from '../../constants/ui';
import useMobileDevice from '../../hooks/useMobileDevice';
import { getRequest } from '../../services';
import { API_URL, getEvents } from '../../constants/apiUrls';
import { getFormattedDateTimeWOSecs } from '../../formatter/date';
import LOGIN_TYPE from '../../constants/loginType';
import FEEDBACK_SCHEMA from '../../components/footer/feedbackForm/feedBackForm.schema';
import COMMON_STYLE from '../../constants/commonStyle';
import { showSnackBar } from '../../../redux/snackBarSlice';
// import WinnersSlider from '../../components/winnerCandidates/winnersSlider';
import styles from './Events.module.css';

import EventRegistrationForm from './EventRegistrationForm';
import RegisterDialog from './RegisterDialog';
import { getLoginDetailFromSession } from '../../helpers/sessionDetails';
import { getSkill } from '../../formatter/commonBootstrap';

function Events({ role, setShowNavBar }) {
  const isMobileDevice = useMobileDevice();
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [termAndConditionDialogOpen, setTermAndConditionDialogOpen] =
    useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState();
  const [pastEvents, setPastEvents] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [eventDetailUrl, setEventDetailUrl] = useState();
  const [showInterestDialog, setShowInterestDialog] = useState(false);
  const [eventName, setEventName] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pastLoading, setPastLoading] = useState(false);
  // const [showWinnersDetails, setShowWinnersDetails] = useState(false);
  const [eventID, setEventID] = useState();
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [assessmentIds, setAssessmentIds] = useState(null);
  const [interviewSkillIds, setInterviewSkillIds] = useState(null);
  const [technologies, setTechnologies] = useState([]);
  const [interviewSkills, setInterviewSkills] = useState([]);

  const {
    register,
    handleSubmit: handleSubmitShowInterest,
    reset: resetShowInterest,
    watch,
    formState: { errors: showInterestErrors },
  } = useForm({
    resolver: yupResolver(FEEDBACK_SCHEMA),
    mode: 'onChange',
    defaultValues: {
      comments: `I am interested in ${eventName}...`,
    },
  });

  const loginDetails = getLoginDetailFromSession();

    useEffect(() => {
      setShowNavBar(true);
    }, [setShowNavBar]);

  // Fetch technologies options from the API
  useEffect(() => {
    const fetchTechnologies = async () => {
      setLoading(true);
      try {
        const res = await axios.get(API_URL.GET_GLOBAL_TECHNOLOGIES);
        const options = res.data.map((tech) => ({
          value: tech.techId,
          label: tech.dispName,
        }));
        setTechnologies(options);
      } catch (err) {
        showSnackBar({
          open: true,
          message: "Failed to fetch technologies. Please try again.",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTechnologies();
  }, []);

  useEffect(() => {
    async function fetchTechSkill() {
      if (interviewSkillIds?.length > 0) {
        try {
          const skills = await Promise.all(interviewSkillIds.map(id => getSkill(id)));
          setInterviewSkills(skills);
        } catch (err) {
          dispatch(
            showSnackBar({
              setopen: true,
              message: err.msg || 'An error occurred while fetching skills',
              severity: 'error',
            })
          );
        }
      }
    }
    fetchTechSkill();
  }, [interviewSkillIds]);

  useEffect(() => {
    if (upcomingEvents === undefined) {
      setLoading(true);
      getRequest(getEvents(true))
        .then((result) => {
          setUpcomingEvents(result);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error?.msg,
              severity: 'error',
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (pastEvents === undefined && showPastEvents) {
      setPastLoading(true);
      getRequest(getEvents(false))
        .then((result) => {
          setPastEvents(result);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error?.msg,
              severity: 'error',
            })
          );
        })
        .finally(() => {
          setPastLoading(false);
        });
    }
  }, [showPastEvents]);

  useEffect(() => {
    resetShowInterest();
  }, [showInterestDialog]);

  // const winnersData = [
  //   {
  //     id: 1,
  //     name: 'John David 1',
  //     image:
  //       'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250',
  //     badge: ['/images/Technical_Intermediate.png'],
  //     jobs: [
  //       {
  //         title: 'Software Enginner',
  //         company: 'Cognizant',
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: 'John David 2',
  //     image:
  //       'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250',
  //     // image: '/images/candidate1.webp',
  //     badge: ['/images/Technical_Intermediate.png'],
  //     // jobs: [
  //     //   // {
  //     //   //   title: 'Software Enginner',
  //     //   //   company: 'Cognizant',
  //     //   // },
  //     // ],
  //   },
  //   {
  //     id: 3,
  //     name: 'John David 3',
  //     image:
  //       'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250',
  //     badge: ['/images/Technical_Intermediate.png'],
  //     jobs: [
  //       {
  //         title: 'Software Enginner',
  //         company: 'Cognizant',
  //       },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     name: 'John David 4',
  //     image:
  //       'https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250',
  //     badge: ['/images/Technical_Intermediate.png'],
  //     // jobs: [
  //     //   // {
  //     //   //   title: 'Software Enginner',
  //     //   //   company: 'Cognizant',
  //     //   // },
  //     // ],
  //   },
  // ];

  function handlePastEvents() {
    setShowPastEvents(!showPastEvents);
  }

  const handleDialogOpen = (url) => {
    setTermAndConditionDialogOpen(true);
    setEventDetailUrl(url);
  };
  // const handleOpenWinnersDetails = (id) => {
  //   setEventID(id);
  //   setShowWinnersDetails(!showWinnersDetails);
  // };
  const handleDialogClose = () => {
    setTermAndConditionDialogOpen(false);
    setEventDetailUrl();
    setIsDialogOpen(false)
  };

  const handleClickRegister = (row) => {
    setSelectedEventId(row.id);
    setStartDate(row.start);
    setEndDate(row.end);
    setEventName(row.event);
    setCompanyName(row.companyName);
    setAssessmentIds(row.assessmentIds);
    setInterviewSkillIds(row.interviewSkillIds);
    setIsDialogOpen(true)
  };

  const handleClickShowInterest = (row) => {
    setShowInterestDialog(true);
    setEventID(row.id);
    setEventName(row.event);
    resetShowInterest({
      comments: `I am interested in ${row.event}...`, 
    });
  };

  const onSubmitShowInterest = async () => {
    try {
      setLoading(true);
      const comments = watch('comments');
      const res = await axios.put(
        `${API_URL.SHOW_INTEREST}/${eventID}/showinterest?message=${encodeURIComponent(comments)}`
      );
      if (res.data.code === -1) {
        dispatch(
          showSnackBar({
            setopen: true,
            message: 'You have already shown interest for this event.',
            severity: 'error',
          })
        );
      } else {
        dispatch(
          showSnackBar({
            setopen: true,
            message: 'Your interest has been submitted successfully!',
            severity: 'success',
          })
        );
        setShowInterestDialog(false);
        resetShowInterest();
      }
    } catch (error) {
      dispatch(
        showSnackBar({
          setopen: true,
          message: 'Submission failed. Please try again.',
          severity: 'error',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  function renderUpcomingEvents() {
    return (
      <TableContainer
        sx={{
          minHeight: 300,
          width: window.innerWidth < 400 ? 300 : '100%',
          overflowX: window.innerWidth < 400 ? 'hidden' : 'scroll',
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <colgroup>
            <col
              style={{
                width: isMobileDevice
                  ? '50%'
                  : '30%'
              }}
            />
            <col style={{ width: isMobileDevice ? '25%' : '20%' }} />
            <col style={{ width: isMobileDevice ? '25%' : '20%' }} />
            <col style={{ width: isMobileDevice ? '30%' : '15%' }} />
            {role === undefined ? <col style={{ width: '20%' }} /> : null}
          </colgroup>
          <TableHead>
            <TableRow>
              {isMobileDevice ? (
                <TableCell>{UI.EVENTS}</TableCell>
              ) : role === LOGIN_TYPE.CANDIDATE ||
                role === LOGIN_TYPE.EMPLOYER ? (
                <>
                  <TableCell>{UI.EVENTS}</TableCell>
                  <TableCell align="left">{UI.COMPANY}</TableCell>
                  <TableCell align="left">{UI.START_DATE}</TableCell>
                  <TableCell align="left">{UI.END_DATE}</TableCell>
                  <TableCell align="left" />
                  <TableCell align="left" />
                </>
              ) : (
                <>
                  <TableCell>{UI.EVENTS}</TableCell>
                  <TableCell align="left">{UI.COMPANY}</TableCell>
                  <TableCell align="left">{UI.START_DATE}</TableCell>
                  <TableCell align="left">{UI.END_DATE}</TableCell>
                  <TableCell align="left" />
                  <TableCell align="left" />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingEvents?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {isMobileDevice ? (
                  <TableCell component="th" scope="row">
                    <a
                      onClick={() => handleDialogOpen(row.details)}
                      className={styles.eventNames}
                    >
                      <div>{row.event}</div>
                    </a>
                    <div className={styles.dateMobileView}>
                      <div>
                        <div>
                          <span>
                            <strong>{row.companyName ? `${UI.COMPANY_NAME} :` : UI.GLOBAL_EVENT}                            </strong>&nbsp;
                          </span>
                          <span>{row.companyName}</span>
                        </div>
                        <div>
                          <span>
                            <strong>{UI.START_DATE}:</strong>&nbsp;
                          </span>
                          <span>{getFormattedDateTimeWOSecs(row.start)}</span>
                        </div>
                        <div>
                          <span>
                            <strong>{UI.END_DATE}:</strong>&nbsp;
                          </span>
                          <span> {getFormattedDateTimeWOSecs(row.end)}</span>
                        </div>
                      </div>
                      {role === LOGIN_TYPE.CANDIDATE ? (
                        row.state === 1 ? (
                          <Button
                            onClick={() => handleClickRegister(row)}
                            variant="contained"
                            disabled={row.registered}
                            style={{
                              whiteSpace: 'nowrap',
                              backgroundColor: row.registered ? '#50C878' : undefined,
                              color: row.registered ? '#ffff' : undefined,
                            }} 
                          >
                            {row.registered === true ? UI.REGISTERED : UI.REGISTER_}
                          </Button>
                        ) : (<strong style={{ padding: '10px 25px 10px 25px', background: '#c8d1de', borderRadius: '5px' }}>{UI.INACTIVE}</strong>)
                      ) : role === LOGIN_TYPE.EMPLOYER ? (
                        row.state === 1 ? (
                          <Button
                            onClick={() => handleClickShowInterest(row)}
                            variant="contained"
                          >
                            {UI.SHOW_INTEREST}
                          </Button>
                        ) : (<strong style={{ padding: '10px 35px 10px 30px', background: '#c8d1de', borderRadius: '5px' }}>{UI.INACTIVE}</strong>)
                      ) : (
                        <div className={styles.btns}>
                          {row.state === 1 ? (
                            <Button
                              onClick={() => handleClickRegister(row)}
                              variant="contained"
                              disabled={row.registered}
                              style={{
                                whiteSpace: 'nowrap',
                                backgroundColor: row.registered ? '#50C878' : undefined,
                                color: row.registered ? '#ffff' : undefined,
                              }} 
                            >
                              {row.registered === true ? UI.REGISTERED : UI.REGISTER_}
                            </Button>
                          ) : (<strong style={{ padding: '10px 25px 10px 25px', background: '#c8d1de', borderRadius: '5px' }}>{UI.INACTIVE}</strong>)
                          }
                        </div>
                      )}
                    </div>
                  </TableCell>
                ) : (
                  <>
                    <TableCell component="th" scope="row">
                      <a
                        onClick={() => handleDialogOpen(row.details)}
                        className={styles.eventNames}
                      >
                        {row.event}
                      </a>
                    </TableCell>
                    <TableCell align="left">
                      {row.companyName ? row.companyName : UI.GLOBAL_EVENT}
                    </TableCell>
                    <TableCell align="left">
                      {getFormattedDateTimeWOSecs(row.start)}
                    </TableCell>
                    <TableCell align="left">
                      {getFormattedDateTimeWOSecs(row.end)}
                    </TableCell>
                    {role === LOGIN_TYPE.EMPLOYER ? (
                      <TableCell align="right">
                        {
                          row.state === 1 ? (
                            <Button
                              onClick={() => handleClickShowInterest(row)}
                              variant="contained"
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {UI.SHOW_INTEREST}
                            </Button>
                          ) : (<strong style={{ padding: '10px 35px 10px 35px', background: '#c8d1de', borderRadius: '5px' }}>{UI.INACTIVE}</strong>)
                        }
                      </TableCell>
                    ) : role === LOGIN_TYPE.CANDIDATE ? (
                      <TableCell align="right">
                        {row.state === 1 ? (
                          <Button
                            variant="contained"
                            onClick={() => handleClickRegister(row)}
                            disabled={row.registered}
                            style={{
                              whiteSpace: 'nowrap',
                              backgroundColor: row.registered ? '#50C878' : undefined,
                              color: row.registered ? '#ffff' : undefined,
                            }}                          >
                            {row.registered === true ? UI.REGISTERED : UI.REGISTER_}
                          </Button>
                        ) : (
                          <strong style={{ padding: '10px 25px 10px 25px', background: '#c8d1de', borderRadius: '5px' }}>{UI.INACTIVE}</strong>
                        )}
                      </TableCell>

                    ) : (
                        <TableCell align="right">
                          {row.state === 1 ? (
                            <Button
                              onClick={() => handleClickRegister(row)}
                              variant="contained"
                              disabled={row.registered}
                              style={{
                                whiteSpace: 'nowrap',
                                backgroundColor: row.registered ? '#50C878' : undefined,
                                color: row.registered ? '#ffff' : undefined,
                              }}                             >
                              {row.registered === true ? UI.REGISTERED : UI.REGISTER_}
                            </Button>
                          ) : (<strong style={{ padding: '10px 25px 10px 25px', background: '#c8d1de', borderRadius: '5px' }}>{UI.INACTIVE}</strong>)
                          }
                        </TableCell>
                    )}
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  function renderPastEvents() {
    return (
      <TableContainer
        sx={{
          minHeight: 300,
          width: window.innerWidth < 400 ? 300 : '100%',
          overflowX: window.innerWidth < 400 ? 'hidden' : 'scroll',
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <colgroup>
            <col style={{ width: isMobileDevice ? '50%' : '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              {isMobileDevice ? (
                <TableCell>{UI.EVENTS}</TableCell>
              ) : (
                <>
                  <TableCell>{UI.EVENTS}</TableCell>
                  <TableCell align="left">{UI.COMPANY}</TableCell>
                  <TableCell align="left">{UI.START_DATE}</TableCell>
                  <TableCell align="left">{UI.END_DATE}</TableCell>
                  <TableCell align="left" />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {pastEvents?.map((row) => (
              <>
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {isMobileDevice ? (
                    <TableCell component="th" scope="row">
                      <a
                        onClick={() => handleDialogOpen(row.details)}
                        className={styles.eventNames}
                      >
                        <div>{row.event}</div>
                      </a>
                      <div className={styles.dateMobileView}>
                        <div>
                          <div>
                            <span>
                              <strong>{UI.COMPANY}:</strong>&nbsp;
                            </span>
                            <span>{row.companyName}</span>
                          </div>
                          <div>
                            <span>
                              <strong>{UI.START_DATE}:</strong>&nbsp;
                            </span>
                            <span>{getFormattedDateTimeWOSecs(row.start)}</span>
                          </div>
                          <div>
                            <span>
                              <strong>{UI.END_DATE}:</strong>&nbsp;
                            </span>
                            <span> {getFormattedDateTimeWOSecs(row.end)}</span>
                          </div>
                        </div>
                        {/* <Button
                          variant="contained"
                          onClick={() => handleOpenWinnersDetails(row.id)}
                        >
                          {UI.WINNERS}
                          {showWinnersDetails && row.id === eventID ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </Button> */}
                      </div>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell component="th" scope="row">
                        <a
                          onClick={() => handleDialogOpen(row.details)}
                          className={styles.eventNames}
                        >
                          {row.event}
                        </a>
                      </TableCell>
                      <TableCell align="left">
                        {row.companyName}
                      </TableCell>
                      <TableCell align="left">
                        {getFormattedDateTimeWOSecs(row.start)}
                      </TableCell>
                      <TableCell align="left">
                        {getFormattedDateTimeWOSecs(row.end)}
                      </TableCell>
                      {/* <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => handleOpenWinnersDetails(row.id)}
                        >
                          {UI.WINNERS}
                          {showWinnersDetails && row.id === eventID ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </Button>
                      </TableCell> */}
                    </>
                  )}
                </TableRow>
                {/* {showWinnersDetails && eventID === row.id ? (
                  <TableRow>
                    <TableCell colSpan={isMobileDevice ? 1 : 4}>
                      <WinnersSlider winnersData={winnersData} />
                    </TableCell>
                  </TableRow>
                ) : null} */}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
      <div className={styles.container}>
        <h3>{UI.UPCOMING_EVENTS}</h3>
        {loading ? (
          <div className="d-flex justify-content-center">
            <CircularProgress size="4rem" />
          </div>
        ) : upcomingEvents?.length > 0 ? (
          renderUpcomingEvents()
        ) : (
          <div className={styles.noEvents}>
            <p>{UI.NO_UPCOMING_EVENTS}</p>
          </div>
        )}
        <div className={styles.dropDown} onClick={() => handlePastEvents()}>
          <h4>{UI.PAST_EVENTS}</h4>
          {showPastEvents ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showPastEvents ? (
          pastLoading ? (
            <div className="d-flex justify-content-center">
              <CircularProgress size="4rem" />
            </div>
          ) : pastEvents?.length > 0 ? (
            renderPastEvents()
          ) : (
            <div className={styles.noEvents}>
              <p>{UI.NO_PAST_EVENTS}</p>
            </div>
          )
        ) : null}
        <TermAndConditionDialog
          termAndConditionDialogOpen={termAndConditionDialogOpen}
          onHandleDialogClose={handleDialogClose}
        >
          <iframe
            title={UI.PRICING_MODEL}
            src={`${eventDetailUrl}?embedded=true`}
            className={styles.pricing_modal}
          />
        </TermAndConditionDialog>
        <RegisterDialog
          isDialogOpen={isDialogOpen}
          onDialogClose={handleDialogClose}
          title={companyName === 'Global' ? 'Global Event Registration' : 'Event Registration'}
          content={
            <EventRegistrationForm
              companyName={companyName}
              eventName={eventName}
              eventID={selectedEventId}
              startDate={getFormattedDateTimeWOSecs(startDate)}
              endDate={getFormattedDateTimeWOSecs(endDate)}
              assessmentIds={assessmentIds}
              technologies={technologies}
              loading={loading}
              interviewSkillIds={interviewSkillIds}
              interviewSkills={interviewSkills}
            />
          }
          isAlert={false}
          isLoading={false}
        />
        <Dialog
          open={showInterestDialog}
          onClose={() => {
            setShowInterestDialog(false);
          }}
          aria-labelledby="forms"
        >
          <DialogTitle sx={{ minWidth: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <strong>{UI.SHOW_INTEREST}</strong>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowInterestDialog(false);
              }}
              sx={COMMON_STYLE.DIALOG_HEADING}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: '50px' }}>
          <div className={styles['form-content']}>
              <strong>{UI.EVENT_NAME}:</strong> {eventName}
            </div>
            <div className={styles['form-content']}>
              <strong>{UI.NAME}:</strong> {loginDetails?.name}
            </div>
            <div className={styles['form-content']}>
              <strong>{UI.EMAIL}:</strong> {loginDetails?.email}
            </div>
            <div className={styles['form-content']}>
              <strong>{UI.PHONE}:</strong> {loginDetails?.mobile}
            </div>
            <div>
              <strong>{UI.COMMENTS}</strong>
              <Box component="span" sx={{ color: '#d32f2f' }}>
                *
              </Box>
            </div>
            <TextField
              {...register("comments")}
              fullWidth
              size="small"
              rows={3}
              type="comments"
              id="comments"
              variant="outlined"
              defaultValue={`I am interested in ${eventName}...`}
              placeholder={UI.COMMENTS_PLACEHOLDER}
              name="comments"
              multiline
              error={!!showInterestErrors.comments?.message}
              helperText={
                showInterestErrors.comments?.message ? showInterestErrors.comments.message : ' '
              }
            />
            <div style={{ paddingBottom: '10px' }}>
              Or write to us at{' '}
              <a href="mailto:contact@alignmycareer.com">
                contact@alignmycareer.com
              </a>
            </div>
            <Button
              onClick={() => handleSubmitShowInterest(onSubmitShowInterest)()}
              size="large"
              fullWidth
              variant="contained"
              type="submit"
              disabled={loading}
              startIcon={
                loading &&
                <CircularProgress size="1rem" color="inherit" />
              }
            >
              {UI.SUBMIT}
            </Button>
          </DialogContent>
          {/* <DialogActions sx={{ marginRight: '12px' }}>
            <Button
              onClick={handleSubmitShowInterest(() => {
                setShowInterestDialog(true);
              })}
              size="large"
              variant="contained"
              type="submit"
              disabled={loading}
              startIcon={
                loading &&
                <CircularProgress size="1rem" color="inherit" />
              }
            >
              {UI.SUBMIT}
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
  );
}
export default Events;
Events.propTypes = {
  role: PropTypes.number,
  setShowNavBar: PropTypes.func,
};
Events.defaultProps = {
  role: undefined,
  setShowNavBar: noop,
};
