import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'RegisterDialog';

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  dialogText: `${PREFIX}-dialogText`,
  closeButton: `${PREFIX}-closeButton`,
};

const Root = styled('div')(() => ({
  [`& .${classes.dialogContent}`]: {
    color: '#000',
  },
  [`& .${classes.dialogText}`]: {
    color: '#000',
  },
  [`& .${classes.closeButton}`]: {
    color: '#000',
  },
}));

function RegisterDialog({ isDialogOpen, onDialogClose, title, content, svg, isAlert, maxWidth }) {
  return (
    <MuiDialog
      open={isDialogOpen}
      onClose={onDialogClose}
      maxWidth={maxWidth || (isAlert ? 'xs' : 'sm')}
    >
      <Root>
        {/* Cross Icon for Manual Close */}
        <Box
          display="flex"
          justifyContent="flex-end"
          padding="8px"
          position="absolute"
          top={0}
          right={0}
          zIndex={1}
        >
          <IconButton onClick={onDialogClose} size="small" className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Dialog Title */}
        <DialogTitle
          display="flex"
          justifyContent="center"
          fontWeight="bold"
          marginTop="10px"
        >{title}</DialogTitle>
    
        {/* Dialog Content */}
        <DialogContent className={classes.dialogContent}>
          {svg && <div className="d-flex justify-content-center">{svg}</div>}
          {content && (
            <DialogContentText id="dialog-description" className={classes.dialogText}>
              {content}
            </DialogContentText>
          )}
        </DialogContent>
      </Root>
    </MuiDialog>
  );
}

RegisterDialog.propTypes = {
  title: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  onDialogClose: PropTypes.func,
  content: PropTypes.node,
  svg: PropTypes.element,
  isAlert: PropTypes.bool,
  maxWidth: PropTypes.string,
};

RegisterDialog.defaultProps = {
  title: undefined,
  isDialogOpen: false,
  onDialogClose: noop,
  content: undefined,
  svg: null,
  isAlert: false,
  maxWidth: undefined,
};

export default RegisterDialog;
